@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow: hidden;
}

.css-185p62g-MlNavigationCompass {
  box-sizing: content-box !important;
}
.css-11hofjy {
  box-sizing: content-box !important;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}

#marker {
  background-size: cover;
  width: 30px;
  height: 38px;
  border-radius: 50%;
  cursor: pointer;
}

.mapboxgl-popup-content {
  width: 300px;
  background-color: transparent !important;
  background: #fff !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  padding: 0px !important;
}

.maplibregl-ctrl-bottom-left {
  margin-left: 60px !important;
}
