#root {
  background-color: #000;
  position: absolute;
  /* min-height: 400px; */
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.mapContainer {
  position: absolute;
  height: 100vh;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 0;
}

.api-content {
  height: 100vh;
  overflow-y: scroll !important;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.api-content::-webkit-scrollbar {
  display: none;
}
